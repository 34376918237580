import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import {
  Embellishment7,
  Embellishment8,
  Embellishment14,
  Embellishment15,
} from "../components/Embellishments"

import "../css/our-work.css"

type PortfolioCompany = {
  title: string
  content: {
    label: string
    description: string
  }
  cta: {
    label: string
    url: string
  }
  featuredImage: any
}

type PortfolioCompanyProps = {
  portfolioCompany: PortfolioCompany
}

function PortfolioCompanyRight({
  portfolioCompany: { title, featuredImage, cta, content },
}: PortfolioCompanyProps) {
  const image = getImage(featuredImage)
  return (
    <div className="portfolio-company lg---flex-reverse w-clearfix">
      <div className="div-block-7">
        <h6 className="h6 label spacing---md">{title}</h6>
        <div className="w-clearfix">
          <div className="our-work-col-1" />
          <div>
            <h3 className="spacing---md">{content.label}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: content.description,
              }}
            />
          </div>
        </div>
        <a
          href={cta.url}
          target="_blank"
          rel="noopener noreferrer"
          className="cta"
        >
          {cta.label}
        </a>
      </div>
      <div className="portfolio-image-side">
        <div className="spacing---lg position---relative">
          <Embellishment7 />
          {image && <GatsbyImage image={image} alt={title} />}
          <div className="notch-3" />
          <div className="block-1" />
        </div>
      </div>
    </div>
  )
}

function PortfolioCompanyLeft({
  portfolioCompany: { title, featuredImage, cta, content },
}: PortfolioCompanyProps) {
  const image = getImage(featuredImage)
  return (
    <div className="portfolioCompany xl---v-padding---3---10-col">
      <div className="w-clearfix">
        <div className="portfolio-image-side">
          <div className="spacing---lg position---relative">
            <Embellishment8 />
            {image && <GatsbyImage image={image} alt={title} />}
            <div className="notch-2" />
            <div className="block-2" />
          </div>
        </div>
        <div className="portfolio---content-side">
          <h6 className="h6 label spacing---md">{title}</h6>
          <div className="w-clearfix">
            <div className="our-work-col-1" />
            <div>
              <h3 className="spacing---md">{content.label}</h3>
              <div
                className="medium"
                dangerouslySetInnerHTML={{
                  __html: content.description,
                }}
              />
              <a
                href={cta.url}
                target="_blank"
                rel="noopener noreferrer"
                className="cta"
              >
                {cta.label}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Section1({
  title,
  fold,
  keep,
  taho,
  embody,
  mezo,
  tbtc,
}: {
  title: string
  fold: PortfolioCompany
  keep: PortfolioCompany
  taho: PortfolioCompany
  embody: PortfolioCompany
  mezo: PortfolioCompany
  tbtc: PortfolioCompany
}) {
  return (
    <div className="section">
      <div className="background---x2">
        <div className="xl---padding-12---1-col">
          <Embellishment14 />
          <div className="xl---v-padding---3---10-col">
            <h1 className="h1 spacing---xxl">{title}</h1>
            <PortfolioCompanyLeft portfolioCompany={mezo} />
          </div>
        </div>
        <div className="xl---padding-12---1-col">
          <PortfolioCompanyRight portfolioCompany={fold} />
        </div>
        <div className="xl---padding-12---1-col">
          <PortfolioCompanyLeft portfolioCompany={tbtc} />
        </div>
        <div className="xl---padding-12---1-col">
          <PortfolioCompanyRight portfolioCompany={keep} />
        </div>
        <div className="xl---padding-12---1-col">
          <PortfolioCompanyLeft portfolioCompany={taho} />
        </div>
        <div className="xl---padding-12---1-col">
          <PortfolioCompanyRight portfolioCompany={embody} />
        </div>
      </div>
    </div>
  )
}

function Section2({
  title,
  content,
}: {
  title: string

  content: {
    label: string
    description: string
    cta: { url: string; label: string }
  }
}) {
  return (
    <div className="section">
      <div className="background---black text---white overflow---hidden xl---flex">
        <div className="xl---width-12---7h float---left md---width-12---12">
          <div className="xl---padding-7h---1-col md---padding-12---1-col">
            <div className="xl---v-padding---3---10-col">
              <h1 className="h1 spacing---lg">{title}</h1>
              <div className="join-up-wrapper spacing---lg">
                <h4>{content.label}</h4>
                <p dangerouslySetInnerHTML={{ __html: content.description }} />
                <Link to={content.cta.url} className="cta invert">
                  {content.cta.label}
                </Link>
              </div>
            </div>
            <Embellishment15 />
          </div>
        </div>
        <div className="xl---width-12---4h float---left overflow---hidden background---mountain md---hidden" />
      </div>
    </div>
  )
}

function OurWorkPage({ data }: { data: { markdownRemark: any } }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      {/* These lines spread GraphQL data to actual props. */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Section1 {...post.frontmatter.portfolio} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Section2 {...post.frontmatter.contact} />
    </Layout>
  )
}

export default OurWorkPage

export const query = graphql`
  query OurWorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        portfolio {
          title
          fold {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
          keep {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1320)
              }
            }
          }
          taho {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
          embody {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
          mezo {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
          tbtc {
            title
            content {
              label
              description
            }
            cta {
              url
              label
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
        }
        contact {
          title
          content {
            label
            description
            cta {
              url
              label
            }
          }
        }
      }
    }
  }
`
